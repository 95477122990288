import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { CheckCircle } from 'react-feather';

export default function Benefits() {
  const groupOne = [
    {
      title: 'Promover la Salud Mental',
      description:
        'Mejorar el bienestar psicológico y emocional a través de un espacio seguro y de apoyo.',
    },
    {
      title: 'Aliviar el Malestar Emocional',
      description: 'Identificar y tratar los síntomas de ansiedad, depresión y estrés.',
    },
    {
      title: 'Desarrollar Habilidades de Afrontamiento',
      description:
        'Aprender a manejar el estrés, regular emociones y resolver problemas eficazmente.',
    },
    {
      title: 'Fomentar el Autoconocimiento',
      description:
        'Facilitar la autoexploración para una mejor comprensión de tus emociones y comportamientos.',
    },
    {
      title: 'Fortalecer Relaciones Interpersonales',
      description:
        'Mejorar la comunicación y resolver conflictos para desarrollar relaciones más significativas.',
    },
    {
      title: 'Promover el Crecimiento Personal',
      description:
        'Apoyarte en la identificación y realización de tus aspiraciones y valores personales.',
    },
  ];

  const groupTwo = [
    {
      title: 'Transiciones de Carrera',
      description: 'Desde cambios de rol hasta reinicios profesionales.',
    },
    {
      title: 'Desarrollo Empresarial',
      description: 'Establecer y alcanzar nuevos objetivos en un entorno cambiante.',
    },
    {
      title: 'Balance Vida-Trabajo',
      description:
        'Encontrar nuevas estrategias para manejar la vida personal y profesional eficazmente.',
    },
    {
      title: 'Adaptación a Nuevos Entornos',
      description: 'Desarrollar resiliencia y flexibilidad ante nuevos desafíos.',
    },
    {
      title: 'Crecimiento Personal Continuo',
      description:
        'Asegurando que cada cambio sea un paso hacia un futuro más satisfactorio y pleno.',
    },
  ];

  return (
    <section id="beneficios" className="relative z-10 px-5 py-10 overflow-hidden bg-white md:py-20">
      <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-7xl text-brand-main">
        <div className="flex flex-col justify-center gap-10 lg:gap-24 xl:gap-16 lg:flex-row ">
          <div className="flex flex-col items-center justify-between mb-5 lg:mb-0 md:items-start">
            <div
              data-aos="zoom-in"
              data-aos-delay="400"
              className="relative flex justify-center w-full rounded-3xl "
            >
              <div className="w-[200px] h-[250px] md:w-[450px] md:h-[500px]  relative">
                <Image
                  fill
                  src="/images/impulsing.jpg"
                  alt="Impulsando Tu Camino Hacia el Éxito Personal"
                  className="  border-2 border-brand-main rounded-3xl !bg-white"
                />
                <div className="absolute top-0 left-0 object-cover w-full h-full translate-x-5 translate-y-5 bg-brand-main-light -z-10 rounded-3xl" />
              </div>
            </div>
          </div>
          <div
            data-aos="fade-in"
            data-aos-delay="200"
            className="mb-10 md:mb-20 mt-[0px] md:mt-0 order-2 md:order-1"
          >
            <h2 className="mb-8 text-2xl md:text-4xl payfair text-brand-secondary">
              Impulsando Tu Camino Hacia el Éxito Personal
            </h2>
            <p className="mb-8 text-sm md:text-base max-w-prose">
              El cambio es una constante en la vida, y adaptarse puede ser un desafío. Mi enfoque en
              coaching y psicología te proporciona las herramientas necesarias para navegar por
              transiciones de vida significativas, ya sean personales, profesionales o
              empresariales. Aquí es cómo te puedo apoyar:
            </p>
            {groupOne.map((item) => (
              <p
                key={item.title}
                className="flex items-center gap-2 mb-2 text-sm text-brand-main-dark"
              >
                <CheckCircle className="min-w-[20px] min-h-[20px] text-brand-secondary" />
                <span>
                  <span className="font-semibold">
                    {item.title}
                    :
                  </span>
                  {' '}
                  {item.description}
                </span>
              </p>
            ))}
            <Link
              href="/#paquetes"
              className="block px-5 py-3 mt-10 font-semibold text-white transition-all duration-200 w-fit payfair bg-brand-btn hover:bg-brand-btn "
            >
              Explora Mis Paquetes
            </Link>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-10 lg:gap-20 xl:gap-10 lg:flex-row">
          <div
            data-aos="zoom-in"
            data-aos-delay="400"
            className="relative mb-5 rounded-3xl lg:order-last lg:mb-0 "
          >
            <div className="w-[200px] h-[250px] md:w-[450px] md:h-[500px] mx-auto  relative ">
              <Image
                fill
                src="/images/guiding.jpg"
                alt="Guiando tu Transformación en Momentos Cruciales"
                className="  border-2 border-brand-main object-cover rounded-3xl !bg-white"
              />
              <div className="absolute top-0 left-0 w-full h-full translate-x-5 translate-y-5 bg-brand-main-light -z-10 rounded-3xl" />
            </div>
          </div>
          <div data-aos="fade-in" data-aos-delay="200" className="mb-10 md:mb-0">
            <h2 className="mb-8 text-2xl md:text-4xl payfair text-brand-secondary">
              Guiando tu Transformación en Momentos Cruciales
            </h2>
            <p className="mb-8 text-sm md:text-base max-w-prose">
              Trabajando juntos, nos enfocamos en lograr tus metas personales y profesionales. Aquí
              están algunos de los objetivos que podemos alcanzar:
            </p>

            {groupTwo.map((item) => (
              <div
                key={item.title}
                className="flex items-center gap-2 mb-2 text-sm text-brand-main-dark"
              >
                <CheckCircle className="min-w-[20px] min-h-[20px] text-brand-secondary" />
                <span>
                  <span className="font-semibold">{item.title}</span>
                  {' '}
                  {item.description}
                </span>
              </div>
            ))}

            <Link
              href="/#paquetes"
              className="block px-5 py-3 mt-10 font-semibold text-white transition-all duration-200 w-fit payfair bg-brand-btn hover:bg-brand-btn "
            >
              Comienza Tu Transformación
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
